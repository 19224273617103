export const bottomBlogPage_contact =[
{"type": "paragraph", "content": [{ "type": "text",
     "text": "Pentru a vă înscrie la acest curs ne puteţi contacta telefonic la numărul (+373) 79413131 sau ne puteți transmite un e-mail la adresa info@fontis-prim.md"
        }]
    }
]
;    

export const bonaCursDef = [ 
{"type": "text",
"text" : "Cursul cuprinde subiecte necesare pentru formarea competenţelor profesionale ale persoanei angajate ca babysitter dar şi ale educatorilor care lucrează în cadrul unor instituţii de învăţământ. Scopul cursului este acela de a oferi cunoştinţe şi abilităţi indispensabile în desfăşurarea profesiei de bonă(babysitter)."
}
];

export const elderlyCareCursDef = [ 
    {"type": "text",
    "text" : "Cursul vizează formarea abilităților și competențelor profesionale necesare în lucrul cu persoanele în vârstă. Programul include cunoștințe teoretice dar și activități practice cu care se pot confrunta persoanele care vor avea în îngrijire o persoană vârstnică. Aceste servicii de îngrijire a persoanelor în vârstă pot fi acordate în instituții sau la domiciliu și se axează pe nevoile identificate ale beneficiarului. Scopul îngrijirilor acordate la domiciliu este de a completa sau de a substitui în întregime îngrjirile acordate de către familie permițând persoanei în vârstă traiul în mediul obișnuit de viață."
    }
    ];

 export const housekeeperCursDef = [ 
        {"type": "text",
        "text" : "Cursul urmărește dobândirea abilităților și cunoștințelor necesare desfășurării în bune condiții a profesiei de menajeră la standarde înalte de calitate, potrivit normelor adoptate și acceptate de Uniunea Europeană. Pentru activitatea desfășurată de către o menajeră este foarte importantă calitatea curățeniei și a întreținerii spațiului de lucru."
        }
        ];