import { elderlyCareCursDef } from "./CursuriTextConst";

const dashline = [
    { "type": "paragraph", "content": [{ "type": "text", "text": "______________" }]}
];

export const c2 = {
    "type": "doc",
    "content": [{
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "Informaţii despre curs: autorizat de Ministerul Educaţiei"
        }]
    },
    //  {
    //     "type": "paragraph",
    //     "content": [{
    //         "type": "text",
    //         "marks": [{
    //             "type": "bold"
    //         }],
    //         "text": "Autorizat de Ministerul Educaţiei"
    //     }]
    // },
     {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "marks": [{
                "type": "bold"
            }],
            "text": "Nr ore curs intensiv"
        }, {
            "type": "text",
            "text": ": 40 ore"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "marks": [{
                "type": "bold"
            }],
            "text": "Nr ore curs extins"
        }, {
            "type": "text",
            "text": ": 80 ore"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "marks": [{
                "type": "bold"
            }],
            "text": "Preţ: contactați-ne"
        }, 
        // {
        //     "type": "hardBreak",
        //     "marks": [{
        //         "type": "bold"
        //     }]
        // }
    ]
    }, ...dashline, 
       ...elderlyCareCursDef, 
    {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "Cunoştinţe dobândite:"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- "
        }, {
            "type": "text",
            "marks": [{
                "type": "italic"
            }],
            "text": "Activitatea profesională a îngrjitorului persoanei în vârstă"
        }, {
            "type": "text",
            "text": " – cuprinde informații despre caracteristicile funcției de îngrijitor al persoanei în vârstă, principiile și responsabilitățile sale, modalități de comportament adecvat cu persoana asistată precum și cu familia acesteia."
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- "
        }, {
            "type": "text",
            "marks": [{
                "type": "italic"
            }],
            "text": "Norme de igienizare"
        }, {
            "type": "text",
            "text": " – oferă informații despre efectuarea și păstrarea igienei personale, a igienei persoanei asistate, igiena lucrurilor și obiectelor personale ale acesteia, precum și despre igiena mobilierului și a încăperilor."
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- "
        }, {
            "type": "text",
            "marks": [{
                "type": "italic"
            }],
            "text": "Asigurarea confortului (fizic și psihic), mobilizarea și transportul persoanei în vârstă"
        }, {
            "type": "text",
            "text": " – oferă informații despre:"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- satisfacerea nevoilor fizice, fiziologice și de sănătate ale persoanei în vârstă asistate;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- satisfacerea nevoilor de dezvoltare fizică, psihică, culturală și spirituală, de integrare socială;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- asistarea la satisfacerea nevoilor fiziologice și patologice;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- transportul cu fotoliul rulant."
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- "
        }, {
            "type": "text",
            "marks": [{
                "type": "italic"
            }],
            "text": "Administrarea alimentației și a medicamentației"
        }, {
            "type": "text",
            "text": " – oferă cunoștințe atât despre alimentația corectă pentru persoanele în vârstă asistate, calcularea rației alimentare precum și administrarea corectă a medicamentelor."
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "-"
        }, {
            "type": "text",
            "marks": [{
                "type": "italic"
            }],
            "text": " Acordarea primului ajutor persoanei în vârstă"
        }, {
            "type": "text",
            "marks": [{
                "type": "bold"
            }],
            "text": " "
        }, {
            "type": "text",
            "text": "– oferă cunoștințe medicale utile și instrucțiuni pentru acordarea primului ajutor în caz de accidente (răniri, fracture, arsuri, entorse, luxații, electrocutări, hemoragii externe) sau de incidente în funcție de afecțiunea persoanei (stop cardio-respirator, disfuncții ale aparatului digestiv sau renal, ale sistemului urinar, traume ortopedice, fracturi de șold, diabet zaharat)."
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- "
        }, {
            "type": "text",
            "marks": [{
                "type": "italic"
            }],
            "text": "Ore practice"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "Informațiile acumulate la cursul “Îngrijire persoane în vârstă” dezvoltă următoarele abilități și competențe:"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - capacități de identificare a nevoilor persoanei în vârstă și satisfacerea acestora;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- capacitatea de a supraveghea și a monitoriza starea de sănătate a vârstnicului;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "  - capacitatea de a interveni prompt în cazuri de accidente și de a oferi primul ajutor;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- capacitatea de a evita factorii de stres și de prevenire a crizelor de stres;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - capacitatea de a respecta condițiile igienico- sanitare;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - capacitatea de comunicare eficientă cu persoana în vârstă asistată, precum și cu familia acesteia, în funcție de caracteristicile personalităților tuturor;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - capacitatea de a efectua orarul zilnic (activități, odihnă, somn), pentru a asigura confortul persoanei asistate;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - capacitatea de a pregăti meniul zilnic și administrarea medicamentelor;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - capacitatea de a asigura transportul persoanei în vârstă, conform tehnicilor de transport specifice;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - capacitatea de gestionare a resurselor materiale și bănești pentru a satisface nevoile imediate ale persoanei asistate;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - capacitatea de a pune în practică cunoștințele dobândite și strategiile de îmbunătățire a calității și a modului de viață ale persoanei în vârstă asistate."
        }]
    }, ...dashline, 
    {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "Pentru a vă înscrie la acest curs ne puteţi contacta telefonic la numărul  (+373) 79413131 sau ne puteți transmite un e-mail la adresa info@fontis-prim.md."
        }]
    }, {
        "type": "paragraph"
    }, 
    // {
    //     "type": "paragraph",
    //     "content": [{
    //         "type": "hardBreak"
    //     }]
    // }
]
};