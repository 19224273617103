import { housekeeperCursDef } from "./CursuriTextConst";

const dashline = [
    { "type": "paragraph", "content": [{ "type": "text", "text": "______________" }]}
];

export const c1 = {
    "type": "doc",
    "content": [{
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "Informaţii despre curs: autorizat de Ministerul Educaţiei"
            }]
        },
        {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": ""
            }]
        },
        {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "Nr ore curs intensiv: 32 ore"
            }]
        }, {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "Pret:  contactați-ne"
            }]
        }, ...dashline,
         ...housekeeperCursDef,
         {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "Cunoştinţe dobândite:"
            }]
        }, {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "- Introducere în profesie: descrierea amplă a profesiei de menajeră, obligațiile și responsabilitățile sale."
            }]
        }, {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "- Etica și comportamentul (ce pot fi respectate și în familie, societate): sunt enumerate regulile de comportament și noțiunile de etică a menajerei."
            }]
        }, {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "- Metode moderne de îngrijire a casei: este prezentată atât o gamă vastă de produse chimice, precum și folosirea, destinația acestora, caracteristicile și aplicarea procesului de curățenie pentru echipamentele, mobilierul din spațiile unde se desfășoară lucrul (apartamente, case, birouri, unități industriale și hoteliere)."
            }]
        }, {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "- Tehnologii de îngrijire a hainelor: sunt descrise tehnologiile moderne de îngrijire a hainelor, tipurile de țesături ale hainelor, aplicarea detergenților și a altor componente chimice folosite la îngrijirea hainelor; metode de călcare a hainelor în funcție de țesături."
            }]
        }, {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "- Pregătirea meselor: sunt explicate tehnici de exploatare a ustensilelor în bucătărie, modul de servire a mesei, planificarea producției de preparate culinare, elaborarea listei de materii prime pentru meniuri, previziunea comenzilor, controlarea calității preparatelor și gestiunea stocurilor de produse."
            }]
        }, {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "- Aspectul legal al angajării: informații utile pentru angajarea în câmpul muncii."
            }]
        }, ...dashline,
         {
            "type": "paragraph",
            "content": [{
                "type": "text",
                "text": "Pentru a vă înscrie la acest curs ne puteţi contacta telefonic la numărul  (+373) 79413131 sau ne puteți transmite un e-mail la adresa info@fontis-prim.md."
            }]
        }
    ]
};


