// import { Editor } from "@tiptap/core";
// import { liftListItem } from "@tiptap/core/dist/packages/core/src/commands/liftListItem";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import TipTapRender from "../utils/TiptapRenderer";
import { c1 } from "./Curs1";
import { c2 } from "./Curs2";
import { c3 } from "./Curs3";
// import { Tiptap } from "./tiptap";
// import StarterKit from '@tiptap/starter-kit'
// import { EditorContent, useEditor } from "@tiptap/react";


//  interface Curs {
//     id: number;
//     name: string;
//     curs:  string;
// }

// export const BlogView = (props: PropsWithRef<any>) => {

    export const BlogPage = (props) => {

    
    // const id = Number.parseInt(props.match.params.id);
    const { id } = useParams();
    // console.log('type of', typeof id, id);
    const [current, setCurrent] = useState(id);
    //const [redirect, setRedirect] = useState(false);
   

    var curs1 = c1;

    var curs2 = c2;
    
    var curs3 = c3; 
  

    const cursuri  =[{id: 1, name: "Curs menajeră", img: "/assets/menajera_goodsize.webp", curs: JSON.stringify(curs1)},
                              {id: 2, name: "Curs îngrijire bătrâni", img: "/assets/elderly-care_goodsize.webp", curs: JSON.stringify(curs2)},
                              {id: 3, name:"Curs bonă (babysitter)", img: "/assets/optim-bona.webp", curs: JSON.stringify(curs3)}] ;
    
    // const [continut, setContinut] = useState(cursuri[id-1].curs);
    
    // const editor = useEditor({
    //     extensions: [
    //       StarterKit,
    //     ],
    //     editable: false,
    //     editorProps: {
    //       attributes: {
    //         class:
    //           "space-y-0 focus:outline-none mr-3 ml-3 flex flex-col text-sm sm:text-base"
    //       }
    //   },
    
    //     content: JSON.parse(cursuri[0].curs),
    //   });
    
    //   useEffect(() => {
          
    //     editor?.commands.setContent(JSON.parse(cursuri[current-1].curs));
    //   },[]);
      

          
     const highlightClass = " bg-gray-300 ";
     //const normalClass = " hover:bg-gray-300";
     const normalClass = " ";

    //  if (redirect) {
    //     console.log('it should redirect');
    //     return <Redirect to={'/location'}/>
    //  }

    //  const selectCurrent = (e: any, key: number) => {
    //         //e.preventDefault();
    //         setCurrent(key);
    //         editor?.commands.setContent(JSON.parse(cursuri[key-1].curs));
            
    //         //stupid idea to reload - but as a test, it worked
    //         //window.location.reload();
    //  }

    const selectCurrent = (e, key) => {
                //e.preventDefault();
                setCurrent(key);
        //         editor?.commands.setContent(JSON.parse(cursuri[key-1].curs));
                  
         }

   // handle the document
   const doc = (props) => (<>{props.children}</>)

   // handle a paragraph
   const paragraph = (props) => {
   return <p>{props.children}</p>
   }

   // handle text
   const text = (props) => {
   // you could process text marks here from props.node.marks ...
   return <span>{props.node.text}</span>
   }

   // handle an image
   const img = (props) => {
   const { src, alt, title } = props.node;
   return (<img src={src} alt={alt} title={title} />)
   }

   // create a handlers wrapper
    const handlers = {
        "doc": doc,
        "text": text,
        "paragraph": paragraph,
        "img": img,
    }

        

    return (

        <section className="text-gray-600 body-font">
            <div className="container px-5 py-1 mx-auto flex flex-col">
                <div className="lg:w-5/6 mx-auto">
                    
                    <div className="flex flex-col sm:flex-row mt-1">
                        
                        <div className="sm:w-1/4 text-center sm:pr-3 sm:py-5">
                            {/* <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                            </div> */}

                             {/* <div className="">
                                <img src="assets/logo_fontis_ro.png" className="object-scale-down h-16 w-16"  alt=""/>
                             </div>    */}
                            <div className="flex flex-col items-center text-center justify-center">
                                <div className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">
                                    <button className="bg-gray-200 p-2">
                                        <Link to="/" className="flex flex-row items-center justify-center text-center" >
                                        <svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M11 5l-7 7l7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M4 12h16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g></svg>
                                            Pagina principala</Link></button>
                                </div>
                                <h2 className="font-medium title-font mt-4 text-gray-900 text-lg mt-24">Categorii</h2>
                                <div className="w-14 h-4 border-t-2 border-indigo-500  mt-2 mb-4" ></div>
                                {/* <p className="text-base">Rqqaclette knausgaard hella meggs normcore williamsburg enamel pin sartorial venmo tbh hot chicken gentrify portland.</p> */}
                            
                            </div>


                           <div className="flex" >
                            <div className=" overflow-auto flex-grow">
                                {/* <div className="mt-3">
                                    <a className="flex items-center h-8 hover:bg-gray-300 text-sm px-3" href="#top">
                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                        </svg>
                                        <span className="ml-2 leading-none">Threads</span>
                                    </a>
                                    <a className="flex items-center h-8 hover:bg-gray-300 text-sm px-3" href="#top">
                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                        </svg>
                                        <span className="ml-2 leading-none font-bold">All DMs</span>
                                    </a>
                                    
                                </div> */}
                                <div className="mt-4">
                                    <div className="flex items-center h-8 px-3 group">
                                        <button id="channels_toggle" className="flex items-center flex-grow focus:outline-none">
                                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                            <span className="ml-2 leading-none font-medium font-bold text-sm">Cursuri</span>
                                        </button>
                                        {/* <button className="items-center justify-center h-6 w-6 ml-auto rounded hidden group-hover:flex hover:bg-gray-300">
                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                            </svg>
                                        </button> */}
                                        {/* <button className="flex items-center justify-center h-6 w-6 ml-1 rounded hover:bg-gray-300">
                                            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                        </button> */}
                                    </div>
                                    <div id="channels_list">
                                       { cursuri.map( (item, index) => {     
                                           var className = "flex items-center h-8 text-sm pl-8 pr-3";
                                            current === item.id ? className += highlightClass : className += normalClass ;
                                            //to={`/cursuri/${item.id}/show`}
                                        return (
                                        <Link to={`/cursuri/${item.id}/show`}
                                             className= {className} 
                                              key = {item.id}  
                                              onClick={(e)=>selectCurrent(e, item.id)}

                                              >
                                            {/* <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M9.243 3.03a1 1 0 01.727 1.213L9.53 6h2.94l.56-2.243a1 1 0 111.94.486L14.53 6H17a1 1 0 110 2h-2.97l-1 4H15a1 1 0 110 2h-2.47l-.56 2.242a1 1 0 11-1.94-.485L10.47 14H7.53l-.56 2.242a1 1 0 11-1.94-.485L5.47 14H3a1 1 0 110-2h2.97l1-4H5a1 1 0 110-2h2.47l.56-2.243a1 1 0 011.213-.727zM9.03 8l-1 4h2.938l1-4H9.031z" clipRule="evenodd" />
                                            </svg> */}
                                              
                                            <div className="flex justify-center w-4">
                                                <span className="w-2 h-2 rounded-full bg-blue-500"></span>
                                            </div>
                                            <span className="ml-2 leading-none ">{item.name}</span>
                                        </Link>
                                        
                                        ) } ) }
                                        
                                    </div>
                                </div>
                                
                            </div>

                         </div>            
                         {/* -----        */}
                        </div>

                        

                        <div className="sm:w-3/4 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 
                                        text-center sm:text-left">
                            <div className="rounded-sm h-78 overflow-hidden ">
                                <img alt="content" className="object-cover object-center h-full w-full" 
                                    src={cursuri[current-1].img} />
                            </div>

                            <div className="flex flex-col items-center text-center justify-center">
                                <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">{cursuri[current-1].name}</h2>
                                <div className="w-36 h-4 border-t-2 border-indigo-500  mt-2 mb-4" ></div>
                            </div>

                            {/* <p className="leading-relaxed text-lg mb-4"> */}
                                <div className="w-full prose max-w-none ">
                                    
                                    {/* <EditorContent editor={editor} /> */}

                                            <TipTapRender handlers={handlers} 
                                                node={JSON.parse(cursuri[current-1].curs)} />

                                    {/* <p>
                                       { cursuri[current-1].curs }
                                    </p> */}
                                </div>
                            {/* </p> */}
                            {/* <a className="text-indigo-500 inline-flex items-center">Learn More
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );

}