import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BlogPage } from '../components/BlogPage';
// import { ContactForm } from '../components/contactform';
// import { Page1 } from '../components/MainPage';
// import { MapLocation } from '../components/location';
// import { BlogPage } from '../components/slackpage';
import { ScrollToTop } from '../utils/ScrollToTop';
import { MainPage } from '../components/MainPage';

// const Wrapper = ({children}) => {
//     const location = useLocation();
//     useLayoutEffect(() => {
//       document.documentElement.scrollTo(0, 0);
//     }, [location.pathname]);
//     return children
//   } 

export const MyRoutes = () => {

    //  {/* <Route path='/admin/products' exact component={Products}/>
    //     <Route path='/admin/products/create' exact component={ProductsCreate}/>
    //     <Route path='/admin/products/:id/edit' exact component={ProductsEdit}/> */}
    return (
        <div>
            
        <BrowserRouter>
        <ScrollToTop/>
           <Routes>
           <Route path="/" exact element={<MainPage/>}/>
          
          {/* <Route path="/main" exact component={Page1}/> */}
          <Route path="/cursuri/:id/show"  exact element={<BlogPage/>}/>
          {/* <Route path="/contactform"  exact component={ContactForm}/> */}
          {/* <Route path="/location"  exact component={MapLocation}/> */}
          {/* <Route path="/slack"  exact component={BlogPage}/> */}
        
        </Routes>
        </BrowserRouter>
        </div>

        
    //     <BrowserRouter>
    // <div>
    // <h1>React Router Example</h1>
    // <ul>
    //   <li><Link to="/home">home</Link></li>
    //   <li><Link to="/cards">Cards</Link></li>
    //   <li><Link to="/form">Form</Link></li>
    //   <li><Link to="/remotedropdown">Remote Dropdown</Link></li>
    //   <li><Link to="/contact">contact</Link></li>
    //   <li><Link to="/tableone">TableOne</Link></li>
    // </ul>

    // <Menu pointing>
    //         {/* augmentation */}
    //      <Menu.Item as={Link} to='/home'>
    //          Homeee
    //       </Menu.Item> 
    //       <Menu.Item as={Link} to='/cards'>
    //          Card
    //       </Menu.Item>
    //       <Menu.Item as={Link} to='/form'>
    //          Form
    //          </Menu.Item> 
          
    //       <Menu.Item as={Link} to='/remotedropdown'>
    //          Remote Dropdown
    //          </Menu.Item> 
    //       <Menu.Item as={Link} to='/tableone'>
    //          TableOne
    //          </Menu.Item>    
    //       <Menu.Item
    //         name='friends' 
    //         onClick={this.handleItemClick}
    //       />
    //       <Menu.Menu position='right'>
    //         <Menu.Item>
    //           <Input icon='search' placeholder='Search...' />
    //         </Menu.Item>
    //       </Menu.Menu>
    //     </Menu>

    // <div>
    //     <Route  path="/cards" component={CardGroupDynamic} />
    //     <Route  path="/home" component={Home} />
    //     <Route  path="/remotedropdown" component={RemoteDropdown} />
    //     <Route  path="/contact" component={Contacts} />        
    //     <Route  path="/form" component={FirstForm} />
    //     <Route  path="/tableone" component={TableOne} />
    // </div>

    //     {/* <Segment>
    //       <img src='/images/wireframe/paragraph.png' />
    //     </Segment> */}
    //   </div>
    //   {/* {this.props.children} */}
    //   </BrowserRouter>
 
    );
}