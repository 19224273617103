import { Link } from "react-router-dom";
import { bonaCursDef, elderlyCareCursDef, housekeeperCursDef } from "./CursuriTextConst";

export const Cards = () => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24
           lg:px-8 lg:py-20">
             <h2 className="max-w-lg mb-6 font-sans text-3xl text-center
              font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
             <span className="relative">Cursuri oferite</span>
             </h2>
        <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
          <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
            <img
              src="assets/menajera_goodsize.webp"
              className="object-cover w-full h-64"
              alt=""
            />
            <div className="p-5 border border-t-0">
              <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                <a
                  href="/"
                  className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                  aria-label="Category"
                  title="Curs acreditat"
                >
                  Curs acreditat
                </a>
                <span className="text-gray-600">— INTENSIV 32 ore</span>
              </p>
              {/* <a
                href="/"
                aria-label="Category"
                title="Curs acreditat"
                className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
              >
                Curs menajeră
              </a> */}
              <div className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700">
                <Link to={`/cursuri/1/show`}> Curs menajeră  </Link>
              </div>

              <p className="mb-2 text-gray-700">
                  {housekeeperCursDef[0].text}
               </p>
              
              {/* <a
                href="/"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Detalii
              </a> */}
              <div className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
                <Link to={`/cursuri/1/show`}> Detalii  </Link>
              </div>
  

            </div>
          </div>
          <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
            <img
              src="assets/elderly-care_goodsize.webp"
              className="object-cover w-full h-64"
              alt=""
            />
            <div className="p-5 border border-t-0">
              <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                <a
                  href="/"
                  className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                  aria-label="Category"
                  title="Curs acreditat"
                >
                  Curs acreditat
                </a>

                
                <span className="text-gray-600" >— INTENSIV 40 ore / EXTINS 80 ore </span>
              </p>
              {/* <a
                href="/"
                aria-label="Category"
                title=""
                className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
              >
                Curs îngrijire bătrâni
              </a> */}
              <div className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700">
                <Link to={`/cursuri/2/show`}> Curs îngrijire bătrâni  </Link>
              </div>
              <p className="mb-2 text-gray-700">
                  {elderlyCareCursDef[0].text}
                </p>
              {/* <a  href="/"  aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Detalii
              </a> */}
              <div className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
                <Link to={`/cursuri/2/show`}> Detalii  </Link>
              </div>
            </div>
          </div>
          <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
            <img
              src="assets/optim-bona.webp"
              className="object-cover w-full h-64"
              alt=""
            />
            <div className="p-5 border border-t-0">
              <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                <a
                  href="/"
                  className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                  aria-label="Category"
                  title="Curs acreditat"
                >
                  Curs acreditat
                </a>
                <span className="text-gray-600" >— EXTINS 360 ore</span>
              </p>
              {/* <a
                href="/"
                aria-label="Category"
                title="Film It!"
                className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
              >
                Curs bonă
              </a> */}
              <div className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700">
                <Link to={`/cursuri/3/show`}> Curs bonă  </Link>
              </div>
              <p className="mb-2 text-gray-700">
                  {bonaCursDef[0].text}
              </p>
              {/* <a
                href="/"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Detalii
              </a> */}
              <div className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
                <Link to={`/cursuri/3/show`}> Detalii  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };