
export  const AboutUs = () => {

return (
   <div>
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" id="top">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="84d09fa9-a544-44bd-88b2-08fdf4cddd38"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#84d09fa9-a544-44bd-88b2-08fdf4cddd38)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">Despre noi</span>
          </span>{' '}
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
        Fontis Prim este un centru privat ce oferă Cursuri de Calificare şi Formare Profesioanală. Programele de curs sunt autorizate de Ministerul Educaţiei din Republica Moldova din anul 2008. Pincipalele cursuri de formare profesioanală pe care le desfăşurăm sunt: îngrijire bătrâni (elderly care), îngrijire copii (babysitter) sau menajeră (housekeeper).
        </p>
      </div>
    </div>

 <section className="text-gray-600 body-font">
  <div className="container px-5 py-0 mx-auto">
    <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
      <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center 
       rounded-full bg-purple-100 text-purple-500 flex-shrink-0">
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
             strokeWidth="1" className="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
        {/* <path fill="none" d="M0 0h24v24H0z" /> */}
      <path d="M11.71 17.99A5.993 5.993 0 016 12c0-3.31 2.69-6 6-6 3.22 0 5.84 2.53 5.99 5.71l-2.1-.63a3.999 3.999 0 10-4.81 4.81l.63 2.1zM22 12c0 .3-.01.6-.04.9l-1.97-.59c.01-.1.01-.21.01-.31 0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8c.1 0 .21 0 .31-.01l.59 1.97c-.3.03-.6.04-.9.04-5.52 0-10-4.48-10-10S6.48 2 12 2s10 4.48 10 10zm-3.77 4.26L22 15l-10-3 3 10 1.26-3.77 4.27 4.27 1.98-1.98-4.28-4.26z" />
    </svg>
      </div>
      <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
        <h2 className="text-gray-900 text-lg title-font font-medium mb-2">Misiune</h2>
        <p className="leading-relaxed text-base">Misiunea noastră constă în îmbunătățirea și dezvoltarea formării profesionale continue a persoanelor adulte prin organizarea cursurilor de formare profesională la cea mai bună calitate, adaptate nevoilor fiecăruia și care au aplicabilitate în exercitarea tuturor profesiilor pentru care “Fontis Prim” SRL deține autorizație.</p>
        {/* <a className="mt-3 text-purple-500 inline-flex items-center" href="#top">Learn More
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a> */}
      </div>
    </div>
    <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
      <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
        <h2 className="text-gray-900 text-lg title-font font-medium mb-2">Viziune</h2>
        <p className="leading-relaxed text-base">Ne dorim să fim recunoscuți ca un adevărat sprijin pentru cei care doresc să-și îmbunătățească cunoștințele, sporindu-și astfel și șansele la un loc de muncă extrem de căutat în zilele noastre și bine plătit.</p>
        {/* <a className="mt-3 text-purple-500 inline-flex items-center" href="#top">Learn More
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a> */}
      </div>
      <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-purple-100 text-purple-500 flex-shrink-0">
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
          {/* <circle cx="6" cy="6" r="3"></circle> */}
          {/* <circle cx="6" cy="18" r="3"></circle> */}
          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
        </svg>
      </div>
    </div>
    <div className="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
      <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-purple-100 text-purple-500 flex-shrink-0">
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
          <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
      </div>
      <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
        <h2 className="text-gray-900 text-lg title-font font-medium mb-2">Valori</h2>
        <p className="leading-relaxed text-base">Fontis Prim are mereu în centrul atenţiei grija pentru oameni, responsabilitatea, inovația şi profesionalismul. Cele mai importante valori pentru obţinerea succesului sunt performanța, dinamismul, încrederea, flexibilitatea, integritatea, perseverența şi orientarea spre rezultat.</p>
        {/* <a className="mt-3 text-purple-500 inline-flex items-center" href="#top">Learn More
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a> */}
      </div>
    </div>
    {/* <button className="flex mx-auto mt-20 text-white bg-purple-500 border-0 py-2 px-8
     focus:outline-none hover:bg-purple-600 rounded text-lg">Button</button> */}
  </div>
</section>

</div>
)

}