
import { Nav } from './SimpleNav';
import { Header } from './Header';
import { useEffect, useState } from 'react';
import { Cards } from './Cards';
import { Footer } from './Footer';
import { AboutUs } from './AboutUs';



export const MainPage = () => {

    const [isScrolled, setIsScrolled] = useState(false);


    const handleScroll = (e) =>{
        if (window.pageYOffset > 0) {
              setIsScrolled(true);
        }else{
            setIsScrolled(false);
        }
       
      }
      useEffect(() => { 
        window.addEventListener("scroll", (e) => handleScroll(e)); 
        
        return () => { 
            // return a cleanup function to unregister our function since its 
            //gonna run multiple times 
            window.removeEventListener("scroll", (e) => handleScroll(e)); }; },
         []);
        


        
    return (
        
        <div className="flexbox ">
           <div className=
                {`fixed  z-50 w-full roundedtransition-all duration-200 ${isScrolled ?
                 " shadow-2xl filter drop-shadow-md bg-gradient-to-r from-gray-200 border-t-2 border-brown-800"
                  : "bg-transparent"}`} >
           
            <Nav/>
            </div>
            <div className=" ">
                <Header/>
            </div>
            <div className="w-screen bg-gray-100">
                <AboutUs/>
            </div>
            <div className="w-screen bg-gray-100">
                <Cards/>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}