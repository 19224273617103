import { bonaCursDef, bottomBlogPage_contact } from "./CursuriTextConst";

const underline = [
    { "type": "paragraph", "content": [{ "type": "text", "text": "______________" }]}
];

const dashline = [
    { "type": "paragraph", "content": [{ "type": "text", "text": "--------------" }]}
];

export const c3 = {
    "type": "doc",
    "content": [
    {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "Informaţii despre curs: autorizat de Ministerul Educaţiei"
        }]
    },
    //  {
    //     "type": "paragraph",
    //     "content": [{
    //         "type": "text",
    //         "marks": [{
    //             "type": "bold"
    //         }],
    //         "text": "autorizat de Ministerul Educaţiei"
    //     }]
    // }, 
    {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "marks": [{
                "type": "bold"
            }],
            "text": "Nr. ore curs extins"
        }, {
            "type": "text",
            "text": ": 360 ore"
        }]
    }, 
     {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "marks": [{
                "type": "bold"
            }],
            "text": "Preţ: contactați-ne"
        }]
    },...underline,
     {
        "type": "paragraph",
        "content": [
            
            ...bonaCursDef
        ]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "Module de curs şi cunoştinţele dobândite:"
        }]
    }, 
    {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "marks": [{
                "type": "italic"
            }],
            "text": "Particularităţile de dezvoltare psihologică a copilului "
        }, {
            "type": "text",
            "text": "- acest modul cuprinde informaţii despre particularităţilor de vârstă a copilului, crizele de vârstă, specificul de lucru cu copii, hiperactivii, agresivii, anxioşii, timizi, copii în procesul de adaptare."
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "Competenţe dezvoltate după parcurgerea modulului:"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - Capacitatea de identificare corectă a problemelor la copii;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - Capacitatea de a interveni prompt şi corect în abordarea situaţiilor dificile;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - Abilitatea de a comunica eficient cu copilul, pentru a-l stimula, încuraja;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - Abilitatea de a educa corect şi a disciplina constructiv."
        }]
    }, {
        "type": "paragraph",
        "content": [ {
            "type": "text",
            "marks": [{
                "type": "italic"
            }],
            "text": " Comunicarea şi relaţionarea cu părinţii copilului"
        }, {
            "type": "text",
            "text": " - acest modul este orientat spre informarea dădacelor despre importanţa colaborării cu familia copilului, stiluri de comunicare eficientă, tipurile de familie şi stilurile de educaţie pe care le practică părinţii şi impactul lor asupra personalităţii copilului."
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "Competenţe dezvoltate după parcurgerea modulului:"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- Capacitatea de a oferi informaţii utile educatorului şi părinţilor despre comportamentul copilului în diferite situaţii;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - Capacitatea de a stabili relaţii de încredere şi colaborare cu părinţii;"
        }]
    }, {
        "type": "paragraph",
        "content": [ {
            "type": "text",
            "marks": [{
                "type": "italic"
            }],
            "text": "Profesia de dădacă abilităţi/competenţe/responsabilităţi"
        }, {
            "type": "text",
            "text": " – scopul acestui modul este de a informa despre trăsăturile de personalitate necesare unei bone, abilităţile utile în procesul de lucru cu educatorul, copii şi părinţii,conştientizarea, gradul de responsabilitate şi dăruire de sine."
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "Competenţe dezvoltate după parcurgerea modulului:"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - Autoanaliza resurselor interne necesare profesiei de dădacă;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": " - Obţinerea cunoştinţelor vizavi de specificul şi profunzimea profesiei de dădacă;"
        }]
    }, {
        "type": "paragraph",
        "content": [{
            "type": "text",
            "text": "- Conştientizarea propriei motivaţii în alegerea profesiei de dădacă."
        }]
    }, ...underline,
     ...bottomBlogPage_contact
 ]
};


