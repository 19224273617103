import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 ">
      <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 
            lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
        {/* <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2  lg:block"
            viewBox="0 0 100 100"
            fill="currentColor"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg> */}
        <svg
          className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
          viewBox="0 0 100 100"
          fill="currentColor"
          preserveAspectRatio="none slice"
        >
          <path d="M50 0H100L50 100H0L50 0Z" />
        </svg>
        {/* <img
          className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src="https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
          alt=""
        /> */}
        <img
          className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src="/assets/optim-hero.webp"
          alt=""
        />
      </div>
      <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
        <div className="mb-16 lg:my-28 lg:max-w-lg lg:pr-5">
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
            Cursuri acreditate
          </p>
          <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            Educație. Performanță.
            <br className="hidden md:block" />
            {' '}
            <span className="inline-block text-deep-purple-accent-400">
              Succes.
            </span>
          </h2>
          <p className="pr-5 mb-1 text-base text-gray-700 md:text-lg">
            Educație și cursuri specializate pentru o meserie de succes. Cursurile sunt acreditate de
          </p>

          <div className="flex-col ">
            <div className="mt-4 mb-6">
              <img className="object-scale-down w-[380px]  h-[90px] rounded shadow-xl border-2"
                 src="assets/logo_minister_new.png" alt="" />
            </div>
            <div></div>

            <div><p className="text-base text-gray-700 md:text-lg">
              Modalitati de contact:</p></div>

            <div className="flex flex-col md:flex-row md:ml-auto md:mr-0 mx-auto items-center flex-shrink-0 space-x-4">
              <button className="w-72 bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none border-2">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                </path></svg>
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="text-xs text-gray-600 mb-1">TELEFON/FAX</span>
                  <span className="title-font text-sm md:text-base">(+373) 79413131</span>
                </span>
              </button>
              <button className="w-80 bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none border-2">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z">
                </path></svg>
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="text-xs text-gray-600 mb-1">E-MAIL</span>
                  <span className="title-font text-sm md:text-base">info@fontis-prim.md</span>
                </span>
              </button>
              
            </div>

            <div className="lg:hidden inline-flex items-center justify-center 
                  h-12 px-6 mt-8 font-medium tracking-wide text-white transition duration-200 rounded
                   shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                {/* <Link to="/contactform" >Formular de contact</Link> */}
                <Link to={`/cursuri/1/show`}> Cursuri disponibile  </Link>
              </div>

            {/* <div className=" w-72 px-7 py-2 
                     text-white transition duration-200 rounded-lg shadow-md bg-deep-purple-accent-400
                      hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                <p >
                  Afla mai multe la:
                  <br className="hidden md:block sm:block" />
                  Tel./fax: (+373) 79413131
                  <br className="hidden md:block sm:block" />
                  
                    E-mail: info@fontis-prim.md{' '}
                </p>
                </div> */}
            {/* <a
                href="/"
                className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              >
                Tel./fax: (+373) 79413131
                E-mail: info@fontis-prim.md
              </a> */}
            {/* <a
                href="/"
                aria-label=""
                className="inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-700"
              >
                Learn more
              </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};